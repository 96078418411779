import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

export const ProgressBar = ({
  bgcolor,
  progress,
  staked,
  totalstaked,
  height,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#7A7D9F",
    borderRadius: 40,
    // marginTop: 50,
    position: "relative",
  };

  const Childdiv = {
    height: "100%",
    width: `${+progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "#10164C",
    fontWeight: 900,
    marginTop: "3rem",
    // position: "absolute",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>
          {" "}
          <span
            style={{
              position: "absolute",
              top: "-2.5rem",
              color: "white",
              // paddingTop: "6rem",
              // right: `${progress}%`,
              fontSize: matches ? "1.5rem" : "1.2rem",
            }}
          >{`${parseInt(staked)}`}</span>
          <img
            src="rocket.png"
            alt=""
            style={{
              width: "100px",
              position: "absolute",
              top: "-1.5rem",
            }}
          />{" "}
          <span
            style={{
              position: "absolute",
              top: "2.1rem",
              right: "-0rem",
              color: "white",
              fontSize: matches ? "1.5rem" : "1.2rem",
            }}
          >
            {parseInt(totalstaked)}
          </span>
        </span>
      </div>
    </div>
  );
};
