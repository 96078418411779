import React from "react";
import { Dialog, DialogContent, Box, Slide, Button } from "@material-ui/core";
import { network_used } from "./utils/environment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NetworkChange({ open, setOpen }) {
  // const { deactivate } = useWeb3React();
  const handleClose = () => {
    setOpen(false);
  };
  const networkHandler = async () => {
    try {
      let chain_used = {
        chainId: "0x1"
      }
      if (network_used == 'testnet') chain_used.chainId = '0xAA36A7';
      
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [chain_used],
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section">
            <Box component="h3" color="#ffffff">
              <Box component="span" color="red" fontSize="30px">
                Error!
              </Box>{" "}
              You are on wrong network please switch to ETH {(network_used == 'mainnet') ? "Mainnet" : "Sepolia Network"}.{" "}
            </Box>
            <Box align="center">
              <Button
                variant="contained"
                style={{
                  background:
                    "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                  color: "#23ead3",

                  boxShadow:
                    "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  fontSize: "1rem",
                  fontWeight: "700",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  fontFamily: '"Work Sans",sans-serif',
                  margin: ".1rem",
                }}
                onClick={networkHandler}
              >
                Change Network
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {/* </Slide> */}
      </div>
    </div>
  );
}

export default NetworkChange;
