import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },

  // typography: {
  //   fontFamily: "Poppins, sans-serif",
  //   h1: {
  //     fontWeight: 700,
  //   },
  //   h2: {
  //     fontWeight: 700,
  //   },
  // },
});

// theme.overrides = {
//   MuiCssBaseline: {
//     "@global": {
//       body: {
//         fontFamily: "Poppins, sans-serif",
//         backgroundColor: "#F9FBFC",
//         color: "#000000",
//       },
//       ".img-fluid": {
//         maxWidth: "100%",
//         height: "auto",
//       },
//     },
//   },
// };

theme = responsiveFontSizes(theme);

export default theme;
