import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
// add 10%
export function calculateGasMargin(value) {
  return +(
    (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
    BigNumber.from(10000)
  ).toFixed(0);
  // return value;
}
//CalculatePayableGas
export const gasEstimationPayable = async (active, fn, index, amount) => {
  if (active) {
    const estimateGas = await fn.deposit(index, MaxUint256).catch(() => {
      return fn.deposit(index, amount);
    });
    return calculateGasMargin(estimateGas);
  }
};

//calculateApproveGas
export const gasEstimationApprove = async (active, fn, cA, amount) => {
  if (active) {
    const ap_estimateGas = await fn.approve(cA, MaxUint256).catch(() => {
      return fn.approve(cA, formatUnits(amount));
    });
    return calculateGasMargin(ap_estimateGas);
  }
};
