import { formatUnits } from "@ethersproject/units";
import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { useStakingContract } from "../hooks";

export default function TotalReward() {
  const [totalEarn, settotalEarn] = useState(0);
  const { account, signer } = useContext(AppContext);
  const StakingContract = useStakingContract(signer);
  const rewarCalculate = () => {
    setInterval(async () => {
      try {
        const earningone = await StakingContract?.calcRewards(account, 1);
        const earningtwo = await StakingContract?.calcRewards(account, 2);
        const earningthree = await StakingContract?.calcRewards(account, 3);

        settotalEarn(
          +formatUnits(earningone) +
          +formatUnits(earningtwo) +
          +formatUnits(earningthree)
        );
      } catch (err) {
        console.log('Error in rewarCalculate Total')
      }
    }, 1000);
  };
  useEffect(() => {
    if (account) {
      rewarCalculate();
    }
  }, [account]);

  return (
    <Box
      color="#00EBB4"
      fontSize={{ sm: "3.5rem", xs: "2rem" }}
      fontWeight="fontWeightBold"
      fontFamily="'Oswald', sans-serif"
    >
      {parseInt(totalEarn).toLocaleString()}
    </Box>
  );
}
