import { formatUnits } from "@ethersproject/units";
import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { useStakingContract } from "../hooks";

export default function Reward2() {
  const [earnTwo, setearnTwo] = useState(0);
  const { account, signer } = useContext(AppContext);
  const StakingContract = useStakingContract(signer);
  const rewarCalculate = () => {
    setInterval(async () => {
      try {
        const earningtwo = await StakingContract?.calcRewards(account, 2);
        setearnTwo(+formatUnits(earningtwo));
      } catch (err) {
        console.log('Error in "rewarCalculate 2"')
      }
    }, 1000);
  };
  useEffect(() => {
    if (account) {
      rewarCalculate();
    }
  }, [account]);
  return (
    <Box
      color="#00EBB4"
      fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
      fontWeight="700"
    >
      {parseInt(earnTwo).toLocaleString()}
    </Box>
  );
}
