// ETH mainnet
export const network_used = 'mainnet'
export const provider_url = "https://mainnet.infura.io/v3/a1da296b9b0f443b8ce3819b652d5967";
export const stakingAddress ="0xD83f623a0a77e09DfC586B831012118Bdf3B4141";
export const tokenAddress = "0x6c936D4AE98E6d2172dB18c16C4b601C99918EE6";

// Sepolia
// export const network_used = 'testnet'
// export const provider_url = "https://sepolia.infura.io/v3/a1da296b9b0f443b8ce3819b652d5967";
// export const stakingAddress = "0xFdE5E54eA4Fca2d8e9Ca26ecD2Af2EB16605e639";
// export const tokenAddress = "0xF3854BB058503312bb2e25975d239b15c275F174";
